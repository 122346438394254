import { formatDate } from '@/utils'
export default {
  filters: { formatDate },
  components: {
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/pagination/Pagination'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Search: () => import(/* webpackChunkName: "button" */ '@/components/icons/Search')
  },
  props: ['prakerjaClass'],
  data() {
    return {
      searchTerm: '',
      keyword: '',
      pageSize: 3,
      totalData: this.prakerjaClass.length,
      currentPage: 1
    }
  },
  computed: {
    filteredClass() {
      let temp = this.prakerjaClass
      if (this.searchTerm !== '' && this.searchTerm) {
        temp = temp.filter((item) => {
          return item.nama_kelas.toString().toUpperCase().includes(this.searchTerm.toUpperCase())
        })
      }
      return temp
    },
    classList() {
      let i, j
      const temporary = []
      const chunk = this.pageSize
      const data = this.filteredClass
      for (i = 0, j = data.length; i < j; i += chunk) {
        temporary.push(data.slice(i, i + chunk))
      }
      return temporary
    }
  },
  watch: {
    filteredClass(val) {
      this.totalData = val.length
    }
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page
    },
    doSearch() {
      this.searchTerm = this.keyword
    }
  }
}
